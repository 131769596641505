import React from "react";
import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import { toast } from "react-toastify";
import TextEditor from "../../components/srf/textEditor";
import axiosWithToken from "./axiosTokenConfig";
import moment from "moment"; // Make sure moment is installed or import accordingly

// DescriptionValue now returns a function to dynamically replace the placeholders with selectedColumn values
const DescriptionValue = {
  srf: (selectedColumn) => `
    <p><br></p>
    <p>Thanks for your business with us. We have received your instruments against your challan No: for calibration Details</p>
    <p><br></p>
    <p>Job No: ${selectedColumn.serviceReqNumber},</p>
    <p>Purchase Order No: ${selectedColumn.poNumber},</p>
    <p>Receipt Date: ${selectedColumn.receiptDate},</p>
    <p>DC/RGP Number: ${selectedColumn.dcNumber},</p>
    <p>DC/RGP Date: ${selectedColumn.dcDate},</p>
    <p>Entry Date: ${selectedColumn.entryDate},</p>
    <p>Committed Delivery Date: ${selectedColumn.committedDeliveryDate},</p>
    <p><br></p>
    <p>Please feel free to contact us in case of any queries, Expecting your valuable order.</p>
    <p><br></p>
    <p>Thanks & Regards,</p>
    <p>Er. Pravin Kashid</p>
    <p>(Founder)</p>
    <p>[LAB City]</p>
    <p><br></p>
    <p>Regd. Office: 129, Vindya Complex, Sector-11,</p>
    <p>CBD Belapur Navi Mumbai, Maharashtra.</p>
    <p>Contact: 9769847865</p>
    <p>Website: <a href="https://bfiinfotech.co.in/" target="_blank" rel="noopener noreferrer">bfiinfotech.co.in</a></p>
    <p>LAB City Is The Product Of BFI</p>
  `,

  invoice: (selectedColumn) => `
    <p><br></p>
    <p><strong>Greetings from BFI Private Limited</strong></p>
    <p><br></p>
    <p>Regarding your inquiry <strong>(No-${selectedColumn.enquiryId} dated ${moment(selectedColumn.enquiryDate).format("YYYY-MM-DD")})</strong>, please find the enclosed Invoice <strong>(Invoice No: ${selectedColumn.invoiceNumber} Dated ${selectedColumn.date})</strong> for Calibration Charges.</p>
    <p>Subject: ${selectedColumn.subject},</p>
    <p>Equipment Collection: ${selectedColumn.equipmentCollection},</p>
    <p>Equipment Delivery: ${selectedColumn.equipmentDelivery},</p>
    <p>Total Quantity: 12,</p>
    <p>Grand Total: ${selectedColumn.totalAmount},</p>
    <p>Onsite Charges: ${selectedColumn.conveyanceCharges},</p>
    <p>Courier Charges: ${selectedColumn.courierCharges},</p>
    <p><br></p>
    <p>Please feel free to contact us in case of any queries, Expecting your valuable order.</p>
    <p><br></p>
    <p>Thanks & Regards,</p>
    <p>Er. Pravin Kashid</p>
    <p>(Founder)</p>
    <p>[LAB City]</p>
    <p><br></p>
    <p>Regd. Office: 129, Vindya Complex, Sector-11,</p>
    <p>CBD Belapur Navi Mumbai, Maharashtra.</p>
    <p>Contact: 9769847865</p>
    <p>Website: <a href="https://bfiinfotech.co.in/" target="_blank" rel="noopener noreferrer">bfiinfotech.co.in</a></p>
    <p>LAB City Is The Product Of BFI</p>
  `,

  quotation: (selectedColumn) => `
  <p>Dear Sir,</p><p><br></p><p>Greetings from Recon Calibration &amp; Test Labs LLP!</p><p><br></p><p>Attached is our Calibration Offer for your review.&nbsp;</p><p><br></p><p>If you have any questions or require further clarification, please feel free to reach out.</p><p><br></p><p>We look forward to receiving your valued purchase order!</p><p><br></p><p><br></p><p>​Best&nbsp;Regards,</p><p><br></p><p><br></p><p>Recon Calibration &amp; Test Labs LLP</p><p>Unit F2, MIDC Plot no. BG/PAP3,</p><p>Opp. The Fern Residency Hotel,</p><p>MIDC, Bhosari, Pune-411026,</p><p>Email : sales@reconcalibration.com</p><p>Mob – 9890143481, 8767431725</p>
`,

  quotation1: (selectedColumn) => {
    if (selectedColumn.enquiryId && selectedColumn.enquiryDate) {
      return `
        <p><br></p>
        <p><strong>Greetings from BFI Private Limited</strong></p>
        <p><br></p>
        <p>Regarding your inquiry <strong>(No-${selectedColumn.enquiryId} dated ${moment(selectedColumn.enquiryDate).format("YYYY-MM-DD")})</strong>, please find the enclosed quotation <strong>(Quotation No: ${selectedColumn.quotationNumber} Dated ${selectedColumn.date})</strong> for Calibration Charges.</p>
        <p><br></p>
        <p>Subject: ${selectedColumn.subject},</p>
        <p>Equipment Collection: ${selectedColumn.equipmentCollection},</p>
        <p>Equipment Delivery: ${selectedColumn.equipmentDelivery},</p>
        <p>Total Quantity: 12,</p>
        <p>Grand Total: ${selectedColumn.totalAmount},</p>
        <p>Onsite Charges: ${selectedColumn.conveyanceCharges},</p>
        <p>Courier Charges: ${selectedColumn.courierCharges},</p>
        <p><br></p>
        <p>Please feel free to contact us in case of any queries, Expecting your valuable order.</p>
        <p><br></p>
        <p>Kindly confirm the receipt of the mail. While sending the Instrument, send all accessories (cables, power cord, etc.) along with the instruments for calibration.</p>
        <p><br></p>
        <p>Thanks & Regards,</p>
        <p>Er. Pravin Kashid</p>
        <p>(Founder)</p>
        <p>[LAB City]</p>
        <p><br></p>
        <p>Regd. Office: 129, Vindya Complex, Sector-11,</p>
        <p>CBD Belapur Navi Mumbai, Maharashtra.</p>
        <p>Contact: 9769847865</p>
        <p>Website: <a href="https://bfiinfotech.co.in/" target="_blank" rel="noopener noreferrer">bfiinfotech.co.in</a></p>
        <p>LAB City Is The Product Of BFI</p>
      `;
    } else {
      return `
        <p><br></p>
        <p><strong>Greetings from BFI Private Limited</strong></p>
        <p><br></p>
        <p>Please find the enclosed quotation <strong>(Quotation No: ${selectedColumn.quotationNumber} Dated ${selectedColumn.date})</strong> for Calibration Charges.</p>
        <p><br></p>
        <p>Subject: ${selectedColumn.subject},</p>
        <p>Equipment Collection: ${selectedColumn.equipmentCollection},</p>
        <p>Equipment Delivery: ${selectedColumn.equipmentDelivery},</p>
        <p>Total Quantity: 12,</p>
        <p>Grand Total: ${selectedColumn.totalAmount},</p>
        <p>Onsite Charges: ${selectedColumn.conveyanceCharges},</p>
        <p>Courier Charges: ${selectedColumn.courierCharges},</p>
        <p><br></p>
        <p>Please feel free to contact us in case of any queries, Expecting your valuable order.</p>
        <p><br></p>
        <p>Kindly confirm the receipt of the mail. While sending the Instrument, send all accessories (cables, power cord, etc.) along with the instruments for calibration.</p>
        <p><br></p>
        <p>Thanks & Regards,</p>
        <p>Er. Pravin Kashid</p>
        <p>(Founder)</p>
        <p>[LAB City]</p>
        <p><br></p>
        <p>Regd. Office: 129, Vindya Complex, Sector-11,</p>
        <p>CBD Belapur Navi Mumbai, Maharashtra.</p>
        <p>Contact: 9769847865</p>
        <p>Website: <a href="https://bfiinfotech.co.in/" target="_blank" rel="noopener noreferrer">bfiinfotech.co.in</a></p>
        <p>LAB City Is The Product Of BFI</p>
      `;
    }
  },
};


const RenderEmailModal = ({ open, handleClose, selectedColumn, refresh, formType }) => {
  const [formData, setFormData] = React.useState({
    userEmail: "",
    clientName: selectedColumn?.clientName || "",
    subject: "",
  });

  const [editorValue, setEditorValue] = React.useState(null);



  const handleEditorChange = (value) => {
    setEditorValue(value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    try {
      let payload = {
        userEmail: formData.userEmail,
        clientName: formData.clientName,
        subject: formData.subject,
        description: editorValue,
      };

      await axiosWithToken.post("http://localhost:5000/api/email", payload);
      setTimeout(refresh, 500);
      toast("Email sent successfully!");
      handleClose();
    } catch (err) {
      toast.error("Error occurred while sending email.");
    }
  };

  React.useEffect(() => {
    // Set default value in the editor based on formType
    if (selectedColumn && formType) {
      setEditorValue(DescriptionValue[formType](selectedColumn));
    }
  }, [selectedColumn, formType]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          width: 800,
          height: "100vh",
          overflowY: "auto",
        }}
      >
        <Typography variant="h5" mb={2}>
          Email Form
        </Typography>
        <TextField
          label="From"
          fullWidth
          name="clientName"
          value={formData.clientName}
          onChange={handleChange}
          sx={{ marginBottom: "20px" }}
        />
        <TextField
          label="To"
          fullWidth
          name="userEmail"
          value={formData.userEmail}
          onChange={handleChange}
          sx={{ marginBottom: "20px" }}
        />
        <TextField
          label="CC"
          fullWidth
          name="cc"
          value={formData.cc}
          onChange={handleChange}
          sx={{ marginBottom: "20px" }}
        />
        <TextField
          label="Subject"
          fullWidth
          name="subject"
          value={formData.subject}
          onChange={handleChange}
          sx={{ marginBottom: "20px" }}
        />

        <TextEditor
          value={editorValue}
          onChange={handleEditorChange}
        />

        <Box sx={{ marginTop: "20px", textAlign: "right" }}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Send Email
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default RenderEmailModal;
